import React from 'react'
// import { Link } from "gatsby"

import ApplySingle from '../components/apply/single'
import Header from '../components/header'
import IntroAlt from '../components/sections/intro/alt'
import Layout from '../components/layout'
import HomeContent from '../components/sections/home-content'
import Partners from '../components/sections/partners'
import SEO from '../components/seo'

const FullAltPage = ({ location }) => (
  <Layout phone="866-740-2901" location={location} showCountDown>
    <SEO
      title="Medicare Offers"
      keywords={[`medicare`, `health`, `insurance`, `plans`, `affordable`, `supplement`]}
    />
    <Header phone="866-740-2901" className="gray-style" grayLogo />
    <IntroAlt
      title="Find Affordable Medicare Insurance in 2 Minutes"
      subTitle="First, just answer a few questions for us so we can help you."
      colType="right-col"
    >
      <ApplySingle className="input-borders full-width" />
    </IntroAlt>
    <main id="main">
      <Partners />
      <HomeContent />
    </main>
  </Layout>
)

export default FullAltPage
